<template>
  <div class="margin-left margin-left-large padding-left">
    <div>
      <ys-input placeholder="搜索用户" prefix-icon="el-icon-search" class="margin-vertical input-search" dark hollow
                v-model="search">
      </ys-input>
      <ys-button class="margin-left" type="secondary" lighting>
        <span><i class="fas fa-qrcode"></i> 签约二维码</span>
      </ys-button>
    </div>
    <ys-infinite-scroll-view ref="scrollbar" style="height: calc(100vh - 96px);" @loadmore="loadMore">
      <div class="information">
        <div class="font-size-extra-large">
          <span>用户列表</span>
          <span class="font-size-medium font-color-secondary">，共<span
              class="font-color-primary">{{ total }}</span>人</span>
          <span class="font-size-medium font-color-secondary">，签约用户：
              <span class="font-color-primary">{{ total }}</span>人</span>
        </div>
        <div class="font-color-secondary">用户管理</div>
      </div>
      <div class="user-list margin-top">
        <div class="list-item layout-horizontal layout-middle" v-for="user in userList" :key="user.user.id"
             @click="$router.push({name: 'user', query: {id: user.user.id}})">
          <div class="layout-horizontal layout-flexible">
            <div class="avatar-container"
                 :class="{
                    'priority-high': user.priority && user.priority.priority === Priority.HIGH,
                    'priority-medium': user.priority && user.priority.priority === Priority.MEDIUM,
                    'priority-low': user.priority && user.priority.priority === Priority.LOW,
                }">
              <el-image class="image-avatar" fit="cover" :src="$prepareImageUrl(user.user.avatar)"
                        v-if="user.user.avatar"></el-image>
              <span class="fa fa-user font-size-extra-large" v-else></span>
            </div>
            <div class="margin-left">
              <div class="font-size-medium layout-horizontal layout-middle">
                <span>{{ user.user.name }}</span>
              </div>
              <div class="font-color-secondary layout-horizontal layout-middle" style="margin-top: 2px;">
                <div class="mark-contract font-size-extra-small margin-right-small" v-if="user.withContract">签约用户</div>
                <span v-for="userTag in user.tags" :key="userTag.id" class="margin-right-small">
                  {{ userTag.tag }}
                </span>
              </div>
            </div>
          </div>
          <i class="fa fa-chevron-right font-color-placeholder"></i>
        </div>
      </div>
    </ys-infinite-scroll-view>
  </div>
</template>

<script>
import YsInput from "@/components/wedigets/YsInput";
import YsInfiniteScrollView from "@/components/wedigets/YsInfiniteScrollView";
import OrganizationUtils from "@/assets/javascript/organization-utils";
import httpapi from "@/assets/javascript/httpapi";
import Priority from "@/assets/javascript/prority";
import YsButton from "@/components/wedigets/YsButton";

function doLoadUser(pageNum) {
  if (this.busyLoadingCode === this.loadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = this.loadingCode;
  this.$reqGet({
    path: '/user/list/get',
    data: {
      organizationId: this.organizationId,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    console.log('pageNum: ' + pageNum + ', ' + res.data.list.length);
    for (let n = 0; n < res.data.list.length; n++) {
      console.log('user: ' + res.data.list[n].id);
    }
    let userList = this.requireClearList ? [] : this.userList;
    this.requireClearList = false;
    this.$appendAfter(userList, res.data.list);
    this.userList = userList;
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
    this.total = res.data.total;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  });
}

function doSearchUser(search, pageNum) {
  if (this.busyLoadingCode === this.loadingCode) {
    return;
  }
  let loadingCode = this.loadingCode;
  this.busyLoadingCode = this.loadingCode;
  this.$reqGet({
    path: '/user/search',
    data: {
      organizationId: this.organizationId,
      search,
      pageNum,
      pageSize: 20,
    }
  }).then(res => {
    if (loadingCode !== this.loadingCode) return;
    let userList = this.requireClearList ? [] : this.userList;
    this.requireClearList = false;
    this.$appendAfter(userList, res.data.list);
    this.userList = userList;
    this.pageNum = pageNum;
    this.totalPages = res.data.pages;
    this.total = res.data.total;
  }).catch(() => {
    if (loadingCode !== this.loadingCode) return;
    this.$message.error('加载失败');
  }).complete(() => {
    if (loadingCode !== this.loadingCode) return;
    this.busyLoadingCode = 0;
  });
}

export default {
  name: "UserList",
  mixins: [httpapi],
  components: {YsButton, YsInfiniteScrollView, YsInput},
  props: {
    organizationId: Number,
  },
  data() {
    return {
      search: null,
      userList: [],
      pageNum: 1,
      totalPages: 0,
      total: 0,
      loadingCode: 1,
      busyLoadingCode: 0,
      searchingTimeoutId: null,
      requireClearList: false,
      Priority,
      scrollTop: 0,
    }
  },
  watch: {
    search: {
      handler() {
        if (this.organizationId) {
          this.startSearch();
        }
      },
      immediate: true,
    }
  },
  methods: {
    loadMore() {
      if (this.pageNum < this.totalPages) {
        console.log('load more: ' + (this.pageNum + 1));
        if (this.search) {
          this.searchUserList(this.pageNum + 1);
        } else {
          this.loadUserList(this.pageNum + 1);
        }
      }
    },
    loadUserList(pageNum = 1) {
      doLoadUser.bind(this)(pageNum);
    },
    searchUserList(pageNum = 1) {
      doSearchUser.bind(this)(this.search, pageNum);
    },
    startSearch() {
      if (this.searchingTimeoutId) {
        clearTimeout(this.searchingTimeoutId);
      }
      this.searchingTimeoutId = setTimeout(() => {
        this.pageNum = 1;
        this.totalPages = 0;
        this.requireClearList = true;
        if (this.search) {
          this.searchUserList();
        } else {
          this.loadUserList();
        }
      })
    }
  },
  activated() {
    let organizationId = OrganizationUtils.$getOrganization().id;
    if (this.organizationId != organizationId) {
      this.organizationId = OrganizationUtils.$getOrganization().id;
      this.loadingCode++;
      this.pageNum = 1;
      this.totalPages = 0;
      this.userList = [];
      this.search = null;
      this.requireClearList = true;
    }
    this.loadUserList(1);
  }

}
</script>

<style scoped>

.input-search {
  width: 400px;
}

.avatar-container {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: 4px solid #bec0c0;
  padding-left: 0px;
  overflow: hidden;
  box-shadow: 0 0 4px #bec0c0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #bec0c0;
  background: #f1f1f1;
  box-sizing: border-box;
}

.avatar-container.priority-high {
  border: 4px solid #f091a6;
  box-shadow: 0 0 4px #f091a6;
}

.avatar-container.priority-medium {
  border: 4px solid #e6a23c;
  box-shadow: 0 0 4px #e6a23c;
}

.avatar-container.priority-low {
  border: 4px solid #98c9a9;
  box-shadow: 0 0 4px #98c9a9;
}

.mark-contract {
  background: #f091a6;
  color: white;
  border-radius: 50px;
  height: 20px;
  display: flex;
  padding: 0px 8px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.list-item {
  max-width: 40%;
}
</style>